<template>
  <header class="flex items-center gap-2 p-4" data-testid="SearchCardHeader">
    <SearchCardHeaderCounter :count="count" />
    <p class="text-xl font-bold uppercase leading-6 text-black">
      {{ $t('search-card.vehicles') }} <br />
      online
    </p>
  </header>
</template>

<script setup lang="ts">
import SearchCardHeaderCounter from './SearchCardHeaderCounter.vue'

interface Props {
  count: number
}

defineProps<Props>()
</script>
