<template>
  <div class="flex items-center justify-center gap-2">
    <SearchCardHeaderNumber v-if="count < 1000" :number="0" />
    <SearchCardHeaderNumber
      v-for="number of count.toString().split('')"
      :key="`${number}${count}`"
      :number="Number(number)"
    />
  </div>
</template>

<script setup lang="ts">
import SearchCardHeaderNumber from './SearchCardHeaderNumber.vue'

interface Props {
  count: number
}

defineProps<Props>()
</script>
